/* Default layers for all Integration projects */
@layer reset,
iac_reset,
iac_base,
iac_tokens,
defaults,
styling,
elements,
application,
utilities,
old_engine_globals,
iac_recipes,
iac_utilities;

@layer reset {
  @tailwind base;

  :root {
    /**
  * Set Scrollbar css variables
  **/
    --scrollbar-width: 8px;
    --scrollbar-height: 8px;
    --scrollbar-border-radius: 3px;
    --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
    --scrollbar-track-color: rgba(0, 0, 0, 0.05);

    /**
  * Padding in content sections
  **/
    --padding-x: 2.18rem;
    --padding-y: 1.5rem;
  }
}

@tailwind components;
@tailwind utilities;

@layer old_engine_globals {
  h1 {
    @apply text-3xl py-4 font-bold;
  }

  h2 {
    @apply text-2xl pt-3 pb-2 font-bold;
  }

  h3 {
    @apply text-lg pb-2 font-bold;
  }

  h4 {
    @apply text-base pb-2 font-bold;
  }

  h5 {
    @apply text-base pb-2 font-normal;
  }

  h6 {
    @apply text-sm pb-2 font-normal;
  }

  th {
    @apply text-left;
  }

  /* # Utils */

  .util-loader {
    @apply h-1.5 rounded-sm;
    @apply bg-gradient-to-r from-white via-primaryColor to-white bg-white;
    background-size: 400% 100%;
    animation: loader 4s linear infinite;
  }
  .util-loader span {
    @apply block mt-2;
  }

  .util-loader--logo {
    @apply h-16 w-20;
  }

  @keyframes loader {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  .loaderWrapper {
    @apply w-full h-full justify-center items-center flex flex-row gap-6 text-black;
  }

  /**
   * Scrollbar
   */
  html.noMacOs ::-webkit-scrollbar {
    width: var(--scrollbar-width, 8px);
    height: var(--scrollbar-height, 8px);
  }
  html.noMacOs ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color, rgba(0, 0, 0, 0.2));
    border: 0 solid;
    border-radius: var(--scrollbar-border-radius, 4px);
  }
  html.noMacOs ::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color, rgba(0, 0, 0, 0.05));
  }
  html.noMacOs ::-webkit-scrollbar-corner {
    background: var(--scrollbar-track-color, rgba(0, 0, 0, 0.05));
    border: 0 solid;
    border-radius: var(--scrollbar-border-radius, 4px);
  }
}
